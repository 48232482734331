<template>
    <div class="add-task-box">
        <el-form  v-loading="loading" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="名称" required prop="name">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="address">
                <el-input v-model="form.address" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                 <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">添加</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        var checkMobile = (rule, value, callback) => {
            if(value==''){
                callback()
            }
            let req1 = /^(\d{3,4}-)?\d{7,8}$/,
            req2 = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
            if(!req2.test(value)&&!req1.test(value)){
                callback(new Error('请输入正确电话号码'));
            }
            callback();
        };
        return {
            options:[
                {
                    value:0,
                    label:"供应商"
                },{
                    value:1,
                    label:"客户 | 领用人 "
                }
            ],
            loading:false,
            form:{
                name:"",
                type:0,//类型，0为进货方，1为出货方
                mobile:"",
                address:"",
                remark:""
            },
            rules:{
                name: [
                    { required: true, message: '请输入单位名称', trigger: 'blur' }
                ],
                mobile: [
                    { validator : checkMobile, trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注内容', trigger: 'blur' },
                    { min: 2, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        onSubmit(){
            //提交
            this.$refs['form'].validate((valid) => {
                //console.log(valid)
                if(valid){
                    this.form.method = "addRelatedParty"
                    this.loading = true
                    console.log(this.form)
                    this.$ajax.post(this.API.api,this.form).then(({msg})=>{
                        this.loading = false
                        this.$message({
                            message: msg||this.CONST.SUC_ADD,
                            type:'success'
                        })
                        this.form.name = ""
                        this.$emit('addSuc')
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }
            })
        }
    }
}
</script>